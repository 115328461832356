import Vue from "vue";

/*TITLE*/
document.title = "Horus | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Horus";
Vue.prototype.$subtitle = "¿Te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Horus";
Vue.prototype.$introSubtitle = "¿Te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ceilan-marfil-copia--20230113110130.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-1-bottega-caliza-copia--20230113110138.jpg";
Vue.prototype.$image_bath2 = "banyo-2-bottega-acero-copia--20230113110146.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ceilan-marfil-copia--20230113110130.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-bottega-caliza-copia--20230113110138.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-bottega-acero-copia--20230113110146.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20martínez%20nº%2011%204ª%20planta%2029005%20málaga",
    text: "C/ Martínez, nº 11, 4ª planta - 29005 Málaga",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20mar%20rojo%20torre%20del%20mar%2029005%20málaga",
    text: "Calle Mar Rojo, Torre del Mar - 29005 Málaga",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:+34662320487",
    text: "+34662320487",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@salsa.es",
    text: "info@salsa.es",
  },
];
